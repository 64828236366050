<template>
  <div>
    <v-banner class="mb-5">
      <h2 class="font-weight-black primary--text">LAPORAN RELAWAN</h2>
    </v-banner>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" block>
          <v-icon left>mdi-text-box</v-icon>
          Buat Laporan
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Buat Laporan</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-col cols="12" md="12">
                <div v-if="!urlImage" class="mb-n5">
                  <v-card
                    class="d-flex align-center justify-center"
                    outlined
                    height="350"
                  >
                    <div>
                      <v-icon>mdi-image</v-icon>
                      <span>Preview Image</span>
                    </div>
                  </v-card>
                </div>

                <div v-else>
                  <v-card outlined>
                    <div>
                      <v-img :src="urlImage" max-height="350"></v-img>
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <span class="subtitle-2">Upload Foto</span>
                <v-file-input
                  class="mt-1"
                  v-model="file"
                  dense
                  flat
                  outlined
                  prepend-icon
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pastikan foto yang kamu kirim terlihat jelas"
                  append-icon="mdi-image"
                  @change="onFile"
                  ref="avatar"
                ></v-file-input>
              </div>

              <div>
                <strong>Detail Laporan</strong>
              </div>

              <v-divider class="my-2"></v-divider>

              <div>
                <div>
                  <span class="subtitle-2">Judul Laporan</span>
                  <v-text-field
                    v-model="laporan_judul"
                    dense
                    flat
                    outlined
                    class="mt-1"
                    autocomplete="off"
                    maxlength="150"
                  ></v-text-field>
                </div>

                <div>
                  <span class="subtitle-2">Deskripsi Laporan</span>
                  <v-textarea
                    v-model="laporan_deskripsi"
                    dense
                    flat
                    outlined
                    class="mt-1"
                    autocomplete="off"
                    maxlength="150"
                  ></v-textarea>
                </div>

                <div>
                  <span class="subtitle-2">Calon Walikota</span>
                  <v-text-field
                    v-model="laporan_walikota"
                    dense
                    flat
                    outlined
                    class="mt-1"
                    autocomplete="off"
                    readonly
                    filled
                  ></v-text-field>
                </div>

                <div>
                  <span class="subtitle-2">Lokasi</span>
                  <v-row class="mt-1" dense>
                    <v-col cols="6">
                      <v-select
                        v-model="kec_id"
                        outlined
                        dense
                        :items="refKec"
                        item-value="kec_id"
                        item-text="kec_nama"
                        :rules="fRules"
                        label="Kecamatan"
                        autocomplete="off"
                        @change="Ckec"
                      ></v-select>
                    </v-col>

                    <v-col cols="6">
                      <v-select
                        v-model="kel_id"
                        outlined
                        dense
                        :items="refKel"
                        item-value="kel_id"
                        item-text="kel_nama"
                        :rules="fRules"
                        label="Kelurahan"
                        autocomplete="off"
                        @change="Ckel"
                      ></v-select>
                    </v-col>

                    <!-- <v-col cols="4">
                      <v-select
                        v-model="tps_id"
                        outlined
                        dense
                        :items="refTps"
                        item-value="tps_id"
                        item-text="tps_nama"
                        :rules="fRules"
                        label="TPS"
                        autocomplete="off"
                      ></v-select>
                    </v-col> -->
                  </v-row>
                </div>

                <hr />

                <div class="text-right mt-5">
                  <v-btn
                    color="primary"
                    depressed
                    :loading="lbtn"
                    :disabled="lbtn"
                  >
                    Simpan
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

export default {
  data: () => ({
    session: "",
    loading: false,
    dev: false,

    dialog: false,
    lbtn: false,

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],

    laporan_foto: "",
    file: null,
    laporan_judul: "",
    laporan_deskripsi: "",
    laporan_walikota: "IDRIS RAHIM - ANDI ILHAM (IDAMAN)",
    urlImage: "",

    refKec: [],
    refKel: [],
    refTps: [],

    kec_id: "",
    kel_id: "",
    tps_id: "",
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.refKec = await getRef.Kec(this.session.token);
  },

  methods: {
    onFile(value) {
      this.laporan_foto = value;
      this.urlImage = URL.createObjectURL(this.laporan_foto);
    },

    async Ckec(value) {
      this.refKel = await getRef.Kel(this.session.token, value);
    },

    async Ckel(value) {
      this.refTps = await getRef.Tps(this.session.token, value);
    },
  },
};
</script>
